<template>
  <div>
    <div class="font-label" :style="labelStyle">
      {{ label }}
    </div>
    <VAutocomplete
      v-bind="$props"
      class="data_filter_select_input"
      :error="error"
      :error-messages="error_message"
      :item-text="itemText"
      :item-value="itemValue"
      :items="options"
      :clearable="readOnly ? !readOnly : clearable"
      :multiple="multiple"
      :no-filter="nofilter"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readOnly"
      v-model="model"
      :rules="validationRules"
      dense
      @change="change"
      @focusout="focusout"
      @update:search-input="updateOptionsDebounce"
      ref="autoCompleteRef"
    >
      <template v-slot:append-item>
        <div v-intersect="onInfiniteScrollHandler" />
      </template>
    </VAutocomplete>
  </div>
</template>

<script>
import debounce from 'lodash-es/debounce';

export default {
  name: 'CustomSelect',
  props: {
    filterParam: { type: String, default: 'name' },
    itemText: { type: String, default: 'value' },
    itemValue: { type: String, default: 'value' },
    label: { type: String, default: '' },
    labelHAlign: { type: String, default: 'left' },
    labelVAlign: { type: String, default: 'middle' },
    labelWidth: { type: String, default: '100px' },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    nofilter: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Selecione o item' },
    totalAmount: { type: Number, default: 50 },
    search: { type: Function },
    searchId: { type: Function },
    validationRules: { type: Array },
    value: { type: [String, Array, Object] },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    clearOnOpen: { type: Boolean, default: true },
  },
  data() {
    return {
      error_message: null,
      error: false,
      itemsPerPage: 20,
      options: [],
      optionsSelected: [],
      filtro: null,
      totalItems: null,
      isMenuActive: null,
      infiniteScrollLoading: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      async set(value) {
        this.validate(value);
        this.$emit('input', value);
        if (this.options) {
          this.options.push(...this.optionsSelected);
        }
      },
    },
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
  },
  async mounted() {
    this.createDropdownWatcher();
    const ref = this.value;
    const refList = [];

    if (ref && this.searchId && !this.multiple) {
      const data = await this.searchId(ref);
      refList.push(data);
    } else if (ref && this.searchId && this.multiple && Array.isArray(ref)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < ref.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const data = await this.searchId(ref[i]);
        if (data) {
          refList.push(data);
        }
      }
    }

    this.optionsSelected.push(...refList);

    const items = await this.handleRequest(null, null, this.itemsPerPage);
    if (items) refList.push(...items);
    this.options.push(...refList);
    this.$forceUpdate();
  },
  methods: {
    async onInfiniteScrollHandler(scrollEventArray) {
      if (!scrollEventArray || scrollEventArray?.length <= 0 || !scrollEventArray[0]?.rootBounds) return;

      const { bottom, height } = scrollEventArray[0]?.rootBounds;
      const { isIntersecting } = scrollEventArray[0];

      if (bottom === 0 || height === 0 || !isIntersecting) {
        return;
      }

      if ((!this.filtro && this.$props.totalAmount > this.itemsPerPage) || this.totalItems > this.itemsPerPage) {
        this.setItem(20);
        this.infiniteScrollLoading = true;
        this.options = await this.handleRequest(null, null, this.itemsPerPage);
        this.infiniteScrollLoading = false;
        this.options.push(...this.optionsSelected);
      }
    },
    createDropdownWatcher() {
      this.$watch(() => this.$refs.autoCompleteRef.$refs.menu.isActive, this.onDropdownChange);
    },
    onDropdownChange(open) {
      this.isMenuActive = true;
      if (open && this.$props.clearOnOpen) this.model = null;
      this.$emit('onDropdownChange', open);
    },
    async updateOptionsHandler(value) {
      if (!this.isMenuActive) return;
      if (value) {
        this.filtro = value;
        this.options = await this.handleRequest(value, this.$props.filterParam, this.itemsPerPage);
        this.options.push(...this.optionsSelected);
      } else this.options = await this.handleRequest();
    },
    // eslint-disable-next-line func-names, prefer-arrow-callback, space-before-function-paren
    updateOptionsDebounce: debounce(function(value) {
      this.updateOptionsHandler(value);
    }, 500),
    setItem(value) {
      this.itemsPerPage += value;
    },
    focusout() {
      this.$emit('focusout');
    },
    validate(value) {
      if (this.validationRules) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.validationRules.length; i++) {
          const validate = this.validationRules[i];
          const result = validate(value);
          this.error = result.hasError;
          this.error_message = result.hasError ? result.message : null;
        }
        this.$forceUpdate();
      }
    },
    updateValue(value) {
      this.$emit('input', value);
    },
    change(value) {
      this.$emit('change', value);
    },
    async handleRequest(param, filter, itemsPerPage) {
      try {
        const { register, totalRegister } = await this.search(param, filter, itemsPerPage || this.itemsPerPage);
        this.totalItems = totalRegister;
        return register || [];
      } catch (error) {
        console.error('Select2Input: Error on handleRequest ', error);
        return [];
      }
    },
  },
};
</script>
