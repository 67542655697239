<template>
  <v-container>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Filtros
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                v-for="(filter, indexFilter) in filters"
                v-bind:key="indexFilter"
                :cols="12"
                :xs="filter.xs || 12"
                :sm="filter.sm || 12"
                :md="filter.md || 6"
                :lg="filter.lg || 3"
                :xl="filter.xl || 3"
              >
                <label class="filter-label"
                  >{{ filter.label }}
                  <div v-if="filter.type === 'select2'">
                    <v-autocomplete
                      style="padding-bottom: 2px;    padding-top: 0px !important;margin-top: 0px !important; margin-top: 0px !important;"
                      class="data_filter_select_input"
                      :no-filter="true"
                      dense
                      :item-text="filter.labelSelect ? filter.labelSelect : 'label'"
                      :item-value="filter.param || 'id'"
                      v-model="filterValues[filter.name]"
                      :items="filter.options"
                      @update:search-input="changeSearch(filter, $event)"
                      @change="onFilterChange($event, filter.name)"
                    >
                    </v-autocomplete>
                  </div>
                  <div v-if="filter.type === 'select2input'">
                    <Select2Input
                      style="padding-bottom: 2px;    padding-top: 0px !important;margin-top: 0px !important; margin-top: 0px !important;"
                      :item-text="filter.labelSelect ? filter.labelSelect : 'label'"
                      :item-value="filter.param || 'id'"
                      label-width="2%"
                      :nofilter="true"
                      :search="filter.service"
                      :totalAmount="filter.totalAmount"
                      :items="filter.options"
                      @input="filterValues[filter.name] = $event"
                      v-model="filterValues[filter.name]"
                      @change="onFilterChange($event, filter.name)"
                    />
                  </div>

                  <custom-select
                    v-if="filter.type === 'CustomSelect'"
                    :item-text="filter.labelSelect ? filter.labelSelect : 'label'"
                    :item-value="filter.param || 'id'"
                    label-width="2%"
                    :nofilter="true"
                    :search="filter.service"
                    :totalAmount="filter.totalAmount"
                    :items="filter.options"
                    @input="filterValues[filter.name] = $event"
                    v-model="filterValues[filter.name]"
                    @change="onFilterChange($event, filter.name)"
                  />

                  <div v-if="filter.type === 'select'">
                    <v-autocomplete
                      style="padding-bottom: 2px;    padding-top: 0px !important;margin-top: 0px !important; margin-top: 0px !important;"
                      class="data_filter_select_input"
                      :no-filter="true"
                      dense
                      item-text="label"
                      item-value="value"
                      v-model="filterValues[filter.name]"
                      :items="filter.options"
                      @change="onFilterChange($event, filter.name)"
                    >
                    </v-autocomplete>
                  </div>
                  <div v-if="filter.type === 'number'">
                    <v-text-field
                      class="data_filter_select_input"
                      type="number"
                      validate-on-blur
                      dense
                      light
                      :placeHolder="filter.placeHolder"
                      :v-model="filterValues[filter.name]"
                      :prepend-inner-icon="filter.prependInnerIcon"
                      @change="onFilterChange($event, filter.name)"
                    />
                  </div>
                  <div v-if="filter.type === 'positive-number'">
                    <v-text-field
                      class="data_filter_select_input"
                      type="number"
                      onkeypress="return (event.key == '.' || event.key == '0' || event.key == '1' || event.key == '2' || event.key == '3' || event.key == '4' || event.key == '5' || event.key == '6' || event.key == '7' || event.key == '8' || event.key == '9')"
                      min="0.0"
                      validate-on-blur
                      dense
                      light
                      :placeHolder="filter.placeHolder"
                      :v-model="filterValues[filter.name]"
                      :prepend-inner-icon="filter.prependInnerIcon"
                      @change="onFilterChange($event, filter.name)"
                    />
                  </div>
                  <div v-if="filter.type === 'text'">
                    <v-text-field
                      class="data_filter_select_input"
                      type="text"
                      validate-on-blur
                      dense
                      light
                      :placeHolder="filter.placeHolder"
                      v-model="filterValues[filter.name]"
                      :prepend-inner-icon="filter.prependInnerIcon"
                      @change="onFilterChange($event, filter.name)"
                    />
                  </div>
                  <div v-if="filter.type === 'search'">
                    <v-text-field
                      class="data_filter_select_input"
                      type="text"
                      validate-on-blur
                      @keydown.enter.prevent="submit"
                      dense
                      light
                      :placeHolder="filter.placeHolder"
                      :no-filter="true"
                      v-model="filterValues[filter.name]"
                      prepend-inner-icon="mdi-magnify"
                      @change="onFilterChange($event, filter.name)"
                    />
                  </div>
                  <div v-if="filter.type === 'date'">
                    <v-text-field
                      class="data_filter_select_input"
                      type="date"
                      validate-on-blur
                      dense
                      light
                      :no-filter="true"
                      v-model="filterValues[filter.name]"
                      prepend-inner-icon="mdi-magnify"
                      @change="onFilterChange($event, filter.name)"
                    />
                  </div>
                  <div v-if="filter.type === 'mask-input-date'">
                    <!-- Este compoente vai ser removido junto com o datafilter -->
                    <mask-input-data-filter
                      class="data_filter_select_input"
                      dense
                      light
                      v-model="filterValues[filter.name]"
                      prepend-inner-icon="mdi-magnify"
                      @input-formatted-value="onMaskInputDateChange($event, filter.name)"
                      :options="dateMaskOptions"
                    />
                  </div>
                  <div v-if="filter.type === 'datetime-local'">
                    <v-text-field
                      class="data_filter_select_input"
                      type="datetime-local"
                      validate-on-blur
                      dense
                      light
                      :no-filter="true"
                      v-model="filterValues[filter.name]"
                      prepend-inner-icon="mdi-magnify"
                      @change="onFilterChange($event, filter.name)"
                    />
                  </div>
                </label>
              </v-col>
              <v-col
                :cols="filterBtnCol.cols"
                :xs="filterBtnCol.xs"
                :sm="filterBtnCol.sm"
                :md="filterBtnCol.md"
                :lg="filterBtnCol.lg || 3"
                :xl="filterBtnCol.xl || 2"
                class="filter_actions pt-4"
                style="align-items:center"
              >
                <v-btn outlined color="primary" @click="clear()" class="mr-2 data_filter_button">
                  Limpar
                </v-btn>
                <v-btn v-promise-btn class="data_filter_button" dark color="primary" @click="filter()">
                  Filtrar
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import CustomSelect from '@/components/custom-select/CustomSelect';
import Select2Input from '../form/input/Select2Input';

// este compoente MaskInputDataFilter será removido.
import MaskInputDataFilter from '../form/input/MaskInputDataFilter';

// noinspection JSVoidFunctionReturnValueUsed
export default {
  components: {
    Select2Input,
    CustomSelect,
    MaskInputDataFilter,
  },
  props: {
    showOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: String,
      required: true,
      default: '',
    },
    orderItemsLabel: {
      type: String,
      required: false,
      default: '',
    },
    orderItemsOptionsLabel: {
      type: String,
      required: false,
      default: '',
    },
    filterBtnCol: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    orderItems: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    orderOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      orderItem: null,
      orderItemOption: null,
      filterValues: {},
      col1LabelWidth: '250px',
      col1LabelHAlign: 'left',
      defaultOrderItem: null,
    };
  },
  computed: {
    orderItemsSelect() {
      return this.loadSelectOptions(this.orderItems);
    },
    orderItemsOptionsSelect() {
      return this.loadSelectOptions(this.orderOptions && this.orderItem ? this.orderOptions[this.orderItem] : null);
    },
    dateMaskOptions() {
      return {
        date: true,
        datePattern: ['d', 'm', 'Y'],
        delimiter: '/',
        dateMin: '01/01/1900', // Optional: Set a minimum date
        dateMax: '12/31/2099', // Optional: Set a maximum date
      };
    },
  },
  methods: {
    async changeSearch(filter, $event) {
      filter.options = await filter.service($event, filter.labelSelect ? filter.labelSelect : 'label');
      this.$forceUpdate();
    },
    submit() {
      this.filter();
    },
    onMaskInputDateChange(value, fieldName) {
      try {
        let formattedDate = '';
        if (!value) {
          this.onFilterChange(value, fieldName);
          return;
        }
        formattedDate = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.onFilterChange(formattedDate, fieldName);
      } catch (error) {
        console.error('Error on DataFilter component at onMaskInputDateChange method. ', error);
        this.onFilterChange(value, fieldName);
      }
    },
    onFilterChange(value, name) {
      this.filterValues[name] = value;
    },
    setOrderItem(orderItem) {
      this.orderItem = orderItem;
    },
    setOrderItemOption(orderItemOption) {
      this.orderItemOption = orderItemOption;
    },
    loadSelectOptions(input) {
      if (!input) {
        return [];
      }
      return Object.keys(input).map((key) => ({
        value: key,
        label: input[key],
      }));
    },
    async clearSelect() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.filters.length; i++) {
        const value = this.filters[i];
        if (value.type === 'select2' || value.type === 'select2input') {
          // eslint-disable-next-line no-await-in-loop
          this.filters[i].options = await value.service();
        }
      }
      const result = this.loadSelectOptions(this.orderItems);
      if (result && result[0]) {
        const [first] = result;
        this.orderItem = first.value;
      }
    },
    clear() {
      this.filters.forEach((filter) => {
        this.filterValues[filter.name] = '';
        this.clearSelect();
      });
      this.filterValues = {};
      this.$forceUpdate();
      this.filter();
    },
    getFilterPrefix(item) {
      const { type } = this.filters.filter((value) => value.name === item)[0];
      if (type === 'search') {
        return '!';
      }
      return '';
    },
    filter() {
      let query = '';
      Object.keys(this.filterValues).forEach((valor) => {
        const operator = this.filters.filter((value) => value.name === valor)[0]?.operator || '=';
        let value = this.filterValues[valor];

        if (typeof value === 'string' && !value.trim()) {
          return;
        }

        value = value.replaceAll(' ', '@');
        const prefix = this.getFilterPrefix(valor);
        query = `${query}${query === '' ? '( ' : ' and ( '}`;
        const item = valor.split(';');
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < item.length; i++) {
          value = value.replaceAll('[', '');
          value = value.replaceAll(']', '');
          if (value && value !== '') {
            query = `${query} ${i > 0 && i < item.length ? 'or ' : ''}${item[i]} ${operator} ${prefix}${value}`;
          }
        }
        query = `${query} )`;
      });
      this.$emit('filter', query);
      this.$forceUpdate();
    },
  },
  async created() {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.filters.length; i++) {
      const value = this.filters[i];
      if (value.type === 'select2' || value.type === 'select2input') {
        // eslint-disable-next-line no-await-in-loop
        this.filters[i].options = await value.service();
      }
    }
    const result = this.loadSelectOptions(this.orderItems);
    if (result && result[0]) {
      const [first] = result;
      this.orderItem = first.value;
    }
  },
};
</script>

<style>
.sortLabel {
  text-align: left;
  font: normal normal medium 16px/24px Gotham Rounded, sans-serif;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}

.v-input__control v-input__slot {
  padding-bottom: 2px !important;
}

.item_option_select_field {
  min-width: 180px;
}

.filter_actions {
  display: flex;
  justify-content: end;
}
</style>
