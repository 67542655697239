import { getUsers } from '@/utils/user-utils';
import { get } from '@/service/api';
import { ChannelOptions } from '@/static-data/ChannelOptions';
import { AttendanceStatusOptions } from '@/static-data/AttendanceStatusOptions';

export default function getFilters() {
  return [
    {
      name: 'id',
      label: 'Código do chamado',
      type: 'text',
      filled: true,
    },
    {
      name: 'initialDate',
      label: 'Data inicial',
      operator: '>',
      type: 'mask-input-date',
      filled: true,
    },
    {
      name: 'finalDate',
      label: 'Data final',
      type: 'mask-input-date',
      operator: '<',
      filled: true,
    },
    {
      name: 'topic.id',
      label: 'Assunto',
      labelSelect: 'title',
      placeHolder: 'Todos',
      type: 'select2',
      service: async (param, filter) => {
        const { data } = await get('ui-integrator/topics/fetch?size=100');
        return data.register;
      },
      filled: true,
    },
    {
      name: 'idOrder',
      label: 'N° do Pedido',
      type: 'text',
      filled: true,
    },
    {
      name: 'status',
      label: 'Status',
      labelSelect: 'label',
      placeHolder: 'Selecione',
      type: 'select',
      filled: true,
      options: AttendanceStatusOptions,
    },
    {
      name: 'seller.id',
      label: 'Seller',
      labelSelect: 'companyName',
      placeHolder: 'Todos',
      type: 'select2',
      service: async (param, filter) => {
        const currentUser = JSON.parse(localStorage.getItem('current_user'));
        const seller = currentUser?.sellers;
        const { data } = await get(`ui-integrator/sellers?sellers=${seller.toString()}`, {
          headers: {
            sellerId: currentUser?.sellerId,
          },
        });
        return data;
      },
      filled: true,
    },
    {
      name: 'attendant.name',
      label: 'Responsavel',
      labelSelect: 'name',
      param: 'name',
      placeHolder: 'Todos',
      type: 'select2',
      service: async (param, filter) => {
        const { data } = await getUsers();
        return data.register;
      },
      filled: true,
    },
    {
      name: 'attendanceChannel',
      label: 'Canal de atendimento',
      labelSelect: 'label',
      placeHolder: 'Selecione',
      type: 'select',
      filled: true,
      options: ChannelOptions,
    },
  ];
}
