const dataCrudMixin = {
  data() {
    return {
      query: '',
      sortable: {},
      page: 0,
      itemsPerPage: 10,
      totalElements: 0,
      content: [],
      laoding: false,
    };
  },
  methods: {
    changeItemsPerPage(data) {
      this.page = 0;
      this.itemsPerPage = data;
      this.init();
    },
    changeFilter(data) {
      this.page = 0;
      this.query = data;
      this.init();
    },
    changeSort(data) {
      this.page = 0;
      this.sortable = data;
      this.init();
    },
    getOrderBy() {
      return Object.keys(this.sortable).join(',');
    },
    getSortBy() {
      return Object.values(this.sortable).join(',');
    },
    changePage(data) {
      this.page = data - 1;
      this.init();
    },
  },
};

export default dataCrudMixin;
