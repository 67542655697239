<template>
  <v-container class="d-flex flex-column">
    <v-row v-if="title" class="container">
      <ActionTitleBar :title="title" :actions="buttonActions" />
    </v-row>

    <v-row class="container">
      <v-container>
        <v-row class="data-list-info" v-if="!!info">
          <span v-html="info"></span>
        </v-row>
        <v-row class="mb-5">
          <slot name="filter"></slot>
        </v-row>
        <v-row class="mb-5">
          <slot name="content"></slot>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import ActionTitleBar from '@/components/template/crud/ActionTitleBar';

export default {
  components: {
    ActionTitleBar,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    showFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: String,
      required: false,
      default: '',
    },
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    buttonActions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    emitFilter(data) {
      this.$emit('emitFilter', data);
    },
  },
  data() {
    return { search: '' };
  },
};
</script>
