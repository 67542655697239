import { get } from '@/service/api';

export default function isAdmin() {
  const item = JSON.parse(localStorage.getItem('current_user'));
  return item.isAdmin;
}

export function typeUserGroup(groups) {
  if (groups) {
    for (let i = 0; i < groups.length; i += 1) {
      if (groups[i].name === 'ADMINISTRADOR') {
        return true;
      }
    }
  }
  return false;
}

export async function getUsers() {
  if (!isAdmin()) {
    return { data: { register: [] } };
  }
  // Essa URL no back-end só pode ser usada por ADMIN.
  return get('/auth/user/fetch');
}
