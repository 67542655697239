import { get } from '@/service/api';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';

export default async function getAllTopics() {
  const { data } = await get('help-assistance-management/topics/fetch-all');
  return data;
}

export async function listTopic(formData = {}, paginationOptions) {
  const { page, itemsPerPage, sortDesc, sortBy } = paginationOptions;
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(sortDesc);
  const sortOptions = buildSortUrl(sortBy, mapedOrder);

  const { title, enabled } = formData;

  const query = [title ? `( title = !${title} )` : '', enabled ? `( enabled = ${enabled} )` : '']
    .filter((condition) => condition !== '')
    .join(' and ');

  const data = await get(`help-assistance-management/topics/fetch?${params}&filter=${query}${sortOptions}`);
  return data;
}
