var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"crud-content",attrs:{"fill-height":""}},[_c('ListLayout',{attrs:{"title":_vm.title,"info":_vm.info,"buttonActions":_vm.buttonActions},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('DataFilter',{attrs:{"search":_vm.search,"filters":_vm.filters},on:{"filter":_vm.changeFilter}})]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"headers":_vm.header,"items":_vm.content,"options":_vm.options,"multi-sort":"","server-items-length":_vm.totalElements,"footer-props":{
          'items-length': 100,
          'items-per-page-text': 'Linhas por página',
          'items-per-page-options': [10, 20, 50],
          pageText: '{0}-{1} de {2}',
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"camelcase",class:_vm.getColorStatus(item.status)},[_vm._v(_vm._s(item.status))])]}},{key:"item.auth",fn:function(ref){
        var item = ref.item;
return [(item.topic.sellerAuthRequired)?_c('div',[(item.hasAuthorizationBySeller)?_c('div',{staticClass:"color--green-0"},[_vm._v("Realizada")]):_c('div',{staticClass:"color--orange-0"},[_vm._v("Pendente")])]):_vm._e()]}},{key:"item.orderId",fn:function(ref){
        var item = ref.item;
return [(item.orderId)?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.openOrder(item.orderId)}}},[_vm._v(_vm._s(item.orderId))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":20,"color":"primary"},on:{"click":function () { return _vm.navigatorToEdit(item.id); }}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.status != 'Concluído' ? 'mdi-message' : 'mdi-magnify-plus-outline')+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.status != 'Concluído' ? 'Responder chamado' : 'Visualizar chamado')+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pt-10 pb-10"},[_vm._v(" Nenhum resultado encontrado ")])]},proxy:true}],null,true)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }