import formatDate from '@/utils/format-utils';

export function mapperToTicketsResponse(formData) {
  if (formData) {
  const tickets = formData;
  tickets.data.register = tickets.data.register.map((item) => ({ ...item, createdAt: formatDate(item.createdAt) }));
  return tickets;
  }
  return formData;
}

export default mapperToTicketsResponse;
