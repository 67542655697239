


















import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
      default: 'Unknow',
    },
    actions: {
      type: Array,
      required: false,
      default: (): [] => [],
    },
  },
});

