import { get, post, remove } from '@/service/api';
import notification from '@/service/notification';
import { mapperToTicketsResponse } from './tickets-mapper';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';

export default async function createMessagesTicket(ticketId, form) {
  const resp = await post(`help-assistance-management/tickets/${ticketId}/messages/`, form);
  if (resp.response && resp.response.status !== 200) {
    notification(resp.response.data.message ? resp.response.data.message : 'Ocorreu um erro na criação da mensagem.', 'error');
    throw new Error(`Erro ao enviar msg${resp}`);
  }
  return resp.data;
}

export async function getMessagesTicket(ticketId) {
  const resp = await get(`help-assistance-management/tickets/${ticketId}/messages/`);
  return resp.data;
}

export async function fetchTickets(page, itemsPerPage, query, sortBy, orderBy) {
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = await remapSortDesc(orderBy);
  const sortOptions = await buildSortUrl(sortBy, mapedOrder);
  const data = await get(`ui-integrator/tickets/fetch?${params}&filter=${query}${sortOptions}`);
  return mapperToTicketsResponse(data);
}

export async function getTicket(ticketId, form) {
  const resp = await get(`ui-integrator/tickets/${ticketId}`);
  return resp.data;
}

export async function finishTicket(ticketId) {
  return post(`ui-integrator/tickets/${ticketId}/finish`);
}

export async function deleteMessage(ticketId, messageId) {
  return remove(`ui-integrator/tickets/${ticketId}/messages/${messageId}`);
}

export async function deleteAttachment(ticketId, attachmentId) {
  return remove(`ui-integrator/tickets/${ticketId}/messages/attachment/${attachmentId}`);
}
