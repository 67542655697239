<template>
  <v-row class='row-space'>
    <div class='font-label' :style='labelStyle'>
      {{ label }}
    </div>
    <v-autocomplete
      style="padding-bottom: 2px;    padding-top: 0px !important;margin-top: 0px !important; margin-top: 0px !important;"
      class="data_filter_select_input"
      :error="error"
      :error-messages="error_message"
      :item-text="itemText"
      :item-value="itemValue"
      :items="options"
      :clearable="readOnly ? !readOnly : clearable"
      :multiple="multiple"
      :no-filter="nofilter"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readOnly"
      v-model="model"
      @change="change"
      @focusout="focusout"
      @update:search-input="updateOptions"
    >
      <template v-slot:append-item>
        <div v-intersect="infiniteLoop"/>
      </template>
    </v-autocomplete>
  </v-row>
</template>

<script>
export default {
  name: 'Select2Input',
  data() {
    return {
      error_message: null,
      error: false,
      itemsPerPage: 20,
      options: [],
      optionsSelected: [],
      filtro: null,
    };
  },
  props: {
    filterParam: { type: String, default: 'name' },
    itemText: { type: String, default: 'value' },
    itemValue: { type: String, default: 'value' },
    label: { type: String, default: '' },
    labelHAlign: { type: String, default: 'left' },
    labelVAlign: { type: String, default: 'middle' },
    labelWidth: { type: String, default: '100px' },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    nofilter: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Selecione o item' },
    totalAmount: { type: Number, default: 50 },
    search: { type: Function },
    searchId: { type: Function },
    validationRules: { type: Array },
    value: { type: [String, Array, Object] },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      async set(value) {
        this.validate(value);
        this.$emit('input', value);
        this.options = await this.search(null, null, this.itemsPerPage);
        this.options.push(...this.optionsSelected);
      },
    },
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
  },
  methods: {
    async updateOptions(value) {
      if (value) {
        this.filtro = value;
        this.options = await this.search(value, this.$props.filterParam, this.$props.totalAmount);
        this.options.push(...this.optionsSelected);
      } else this.options = await this.search();
    },
    async infiniteLoop() {
      if (!this.filtro && this.$props.totalAmount > this.itemsPerPage) {
        this.setItem(20);
        this.options = await this.search(null, null, this.itemsPerPage);
        this.options.push(...this.optionsSelected);
      }
    },
    setItem(value) {
      this.itemsPerPage += value;
    },
    focusout() {
      this.$emit('focusout');
    },
    validate(value) {
      if (this.validationRules) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.validationRules.length; i++) {
          const validate = this.validationRules[i];
          const result = validate(value);
          this.error = result.hasError;
          this.error_message = result.hasError ? result.message : null;
        }
        this.$forceUpdate();
      }
    },
    updateValue(value) {
      this.$emit('input', value);
    },
    change(value) {
      this.$emit('change', value);
    },
  },
  async mounted() {
    const ref = this.value;
    const refList = [];
    if (ref && this.searchId && !this.multiple) {
      const data = await this.searchId(ref);
      refList.push(data);
    } else if (ref && this.searchId && this.multiple && Array.isArray(ref)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < ref.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const data = await this.searchId(ref[i]);
        if (data) {
          refList.push(data);
        }
      }
    }
    const items = await this.search();
    refList.push(...items);
    this.optionsSelected.push(...refList);
    this.options.push(...refList);
    this.$forceUpdate();
  },
};
</script>

<style scoped>

</style>
