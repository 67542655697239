import formatDate, { setTicketStatusColor } from '@/utils/format-utils';

const decorators = [
  {
    column: 'id',
    width: '20%',
    sortable: true,
  },
  {
    column: 'auth',
    type: 'string',
    chipTextColorFunction: (item) => {
      let response = '';
      const { orderInfoRequired } = item.topic;
      const { sellerAuthRequired } = item.topic;
      if (orderInfoRequired) {
        response = item.hasAuthorizationByAttendant ? 'green' : 'orange';
      }
      if (sellerAuthRequired) {
        response = item.hasAuthorizationBySeller ? 'green' : 'orange';
      }
      return response;
    },
    formatterFunction: (item) => {
      let response = null;
      const { orderInfoRequired } = item.topic;
      const { sellerAuthRequired } = item.topic;
      if (orderInfoRequired) {
        response = item.hasAuthorizationByAttendant ? 'Realizada' : 'Pendente';
      }
      if (sellerAuthRequired) {
        response = item.hasAuthorizationBySeller ? 'Realizada' : 'Pendente';
      }
      return response;
    },
  },
  {
    column: 'createdAt',
    type: 'string',
    formatterFunction: (item) => formatDate(item.updatedAt),
  },
  {
    column: 'status',
    chipTextColorFunction: (item) => setTicketStatusColor(item),
    fontBold: true,
  },
];

export default decorators;
