<template>
  <div>
    <div v-if="$store.getters.isAdmin"><AdminList /></div>
    <div v-else><SellerList /></div>
  </div>
</template>

<script>
import AdminList from './admin/AdminList';
import SellerList from './seller/SellerList';

export default {
  name: 'List',
  components: { SellerList, AdminList },
};
</script>
