<template>
  <v-container class="crud-content" fill-height>
    <ListLayout :title="title" :info="info" :buttonActions="buttonActions">
      <template v-slot:filter>
        <DataFilter @filter="changeFilter" :search="search" :filters="filters" />
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="header"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="options"
          multi-sort
          :server-items-length="totalElements"
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.status`]="{ item }">
            <div class="camelcase" :class="getColorStatus(item.status)">{{ item.status }}</div>
          </template>
          <template v-slot:[`item.auth`]="{ item }">
            <div v-if="item.topic.sellerAuthRequired">
              <div class="color--green-0" v-if="item.hasAuthorizationBySeller">Realizada</div>
              <div class="color--orange-0" v-else>Pendente</div>
            </div>
          </template>
          <template v-slot:[`item.orderId`]="{ item }">
            <a v-if="item.orderId" @click.prevent="openOrder(item.orderId)">{{ item.orderId }}</a>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :size="20" @click="() => navigatorToEdit(item.id)" color="primary" v-bind="attrs" v-on="on">
                  {{ item.status != 'Concluído' ? 'mdi-message' : 'mdi-magnify-plus-outline' }}
                </v-icon>
              </template>
              {{ item.status != 'Concluído' ? 'Responder chamado' : 'Visualizar chamado' }}
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
  </v-container>
</template>

<script>
import ListLayout from '@/components/template/list/ListLayout';
import DataFilter from '@/components/template/list/DataFilter';
import dataCrudMixin from '../../../components/template/crud/mixins/dataCrudMixin';
import { AttendanceStatusOptions } from '@/static-data/AttendanceStatusOptions';
import getAllTopics from '@/service/topics/topics-service';
import formatDate from '@/utils/format-utils';
import decorators from '../props/decorators';
import { fetchTickets } from '@/service/ticket/tickets-service';
import { routerPush } from '@/service/kplaceRouter';
import getColorStatus from '../util/status';

export default {
  name: 'SellerList',
  components: {
    ListLayout,
    DataFilter,
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      decorators,
      title: 'Atendimento',
      info: '',
      addFunctionName: 'Solicitar Atendimento',
      atributeToEdit: 'id',
      loading: false,
      search: '',
      options: {},
      header: [
        { text: 'Chamado', sortable: true, value: 'id' },
        {
          text: 'Data e hora',
          value: 'createdAt',
          formatter: formatDate,
          sortable: true,
        },
        { text: 'Assunto', sortable: true, value: 'topic.title' },
        {
          text: 'Pedido',
          sortable: true,
          align: 'start',
          value: 'orderId',
        },
        {
          text: 'Autorização',
          sortable: false,
          align: 'start',
          value: 'auth',
        },
        {
          text: 'Status',
          align: 'start',
          value: 'status',
          sortable: true,
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      filters: [
        {
          name: 'id',
          label: 'Código do chamado',
          type: 'text',
          filled: true,
        },
        {
          name: 'initialDate',
          label: 'Data inicial',
          operator: '>',
          type: 'mask-input-date',
          filled: true,
        },
        {
          name: 'finalDate',
          label: 'Data final',
          type: 'mask-input-date',
          operator: '<',
          filled: true,
        },
        {
          name: 'topic.id',
          label: 'Assunto',
          labelSelect: 'title',
          placeHolder: 'Todos',
          type: 'select2',
          service: async () => getAllTopics(),
          filled: true,
        },
        {
          name: 'idOrder',
          label: 'N° do Pedido',
          type: 'text',
          filled: true,
        },
        {
          name: 'status',
          label: 'Status',
          labelSelect: 'label',
          placeHolder: 'Selecione',
          type: 'select',
          filled: true,
          options: AttendanceStatusOptions,
        },
      ],
      buttonActions: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
    },
  },
  async mounted() {
    if (this.options.sortBy.length === 0) {
      this.options.sortBy.push('createdAt');
      this.options.sortDesc.push(false);
    }
    await this.init();
    this.buttonActions.push({
      name: 'Solicitar Atendimento',
      action: this.openAttendance,
    });
  },
  methods: {
    getColorStatus,
    async init() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const response = await fetchTickets(page, itemsPerPage, this.query, sortBy, sortDesc);
      this.content = response.data.register;
      this.totalElements = response.data.totalRegister;
      this.loading = false;
    },
    navigatorToEdit(id) {
      routerPush(`/attendance/forms/edit/${id}`);
    },
    openOrder(id) {
      routerPush(`/order/detail/${id}`);
    },
    openAttendance() {
      routerPush('/attendance/forms');
    },
  },
};
</script>
<style lang="scss" scoped>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}

.attendance-status {
  &--finished {
    color: #02d13f;
  }
  &--cancelled {
    color: #ff3838;
  }
  &--going {
    color: #868d96;
  }
  &--waitting {
    color: #07bee7;
  }
  &--default {
    color: #6c757d;
  }
}
</style>
