import { TicketStatusEnum } from '@/enums/TicketStatusEnum';

export default function formatDate(item) {
  if (typeof item !== 'undefined') {
    const date = item.substring(0, item.indexOf('T'));
    const [year, month, day] = date.split('-');
    const [hour, minute] = item.split('T')[1].split(':');
    const finalDate = `${day}/${month}/${year} ${hour}:${minute}`;
    return finalDate;
  }
  return 'Não informado';
}

export function formatDateWithoutHour(item) {
  if (typeof item !== 'undefined') {
    const date = item.substring(0, item.indexOf('T'));
    const [year, month, day] = date.split('-');
    const finalDate = `${day}/${month}/${year}`;
    return finalDate;
  }
  return 'Não informado';
}

export function formatDateBrazil(item) {
  const [year, month, day] = item.split('-');
  const finalDate = `${day}-${month}-${year}`;
  return finalDate;
}

function formatNumber(number, count) {
  return number.toString().padStart(count || 2, '0');
}

export function formatMountDate(year, month, day) {
  return [year, formatNumber(month + 1), formatNumber(day)].join('-');
}

export function formatterTextFromBoolean(item, trueText, falseText) {
  switch (item) {
    case true: return trueText;
    case false: return falseText;
    default: return 'N/A';
  }
}

export function setTicketStatusColor(item) {
  switch (item.status) {
    case TicketStatusEnum.FINISHED:
      return '#02d13f';
    case TicketStatusEnum.CANCELLED:
      return '#ff3838';
    case TicketStatusEnum.ON_GOING:
      return '#868d96';
    case TicketStatusEnum.WAITING:
      return '#07BEE7';
    default:
      return '#6C757D';
  }
}
