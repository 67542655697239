import { TicketStatusEnum } from '@/enums/TicketStatusEnum';

export const statusEnum = {
  EM_ABERTO: 'EM ABERTO',
  AGUARDANDO_FECHAMENTO: 'AGUARDANDO FECHAMENTO',
  PAGA: 'PAGA',
  FECHADA: 'FECHADA',
};

export default function getColorStatus(status) {
  switch (status) {
    case TicketStatusEnum.CANCELLED:
      return 'attendance-status--cancelled';
    case TicketStatusEnum.WAITING:
      return 'attendance-status--waitting';
      case TicketStatusEnum.ON_GOING:
      return 'attendance-status--going';
    case TicketStatusEnum.FINISHED:
      return 'attendance-status--finished';
    default:
      return 'attendance-status--default';
  }
}

export const statusOptions = [
  { label: 'Em aberto', value: statusEnum.EM_ABERTO },
  { label: 'Aguardando fechamento', value: statusEnum.AGUARDANDO_FECHAMENTO },
  { label: 'Paga', value: statusEnum.PAGA },
  { label: 'Fechada', value: statusEnum.FECHADA },
];
